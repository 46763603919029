$warning-text: var(--warning-text, #f79b20);
$warning-bg: var(--warning-bg, #f79b20);
$warning-border: var(--warning-border, #f79b20);

$error-text: var(--error-text, #e62324);
$error-bg: var(--error-bg, #e62324);
$error-border: var(--error-border, #e62324);

$dark-text: var(--dark-text, #373a4c);
$dark-bg: var(--dark-bg, #373a4c);
$dark-border: var(--dark-border, #373a4c);
$dark-shadow: var(--dark-shadow, #373a4c1f);
$dark-shadow2: var(--dark-shadow2, #373a4c33);

$white-text: var(--white-text, #fff);
$white-bg: var(--white-bg, #fff);
$white-border: var(--white-border, #fff);

$blue-text: var(--blue-text, #0098ff);
$blue-bg: var(--blue-bg, #0098ff);
$blue-border: var(--blue-border, #0098ff);

$green-text: var(--green-text, #39b54a);
$green-bg: var(--green-bg, #39b54a);
$green-border: var(--green-border, #39b54a);

$gray-bg: var(--gray-bg, #afb0b7);
$gray-border: var(--gray-border, #afb0b7);
$gray-border10: var(--gray-border10, #ebebed);

$yellow-bg: var(--yellow-bg, #ffcc00);

$label-color: #afb0b7;
$disabled-bg: #ddd;
