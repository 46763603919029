@import 'src/variables.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  min-width: 320px;
}

body,
html {
  height: 100vh;

  &.ios-polyfill {
    height: 110vh;
  }
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.MuiTabs-flexContainer button {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: initial;
}

.MuiTabs-root .MuiTabs-indicator {
  height: 4px;
  background-color: $error-bg;
}

.time-registration-section-label {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: $dark-text;
  margin: 36px 0 0px;
  padding: 0 16px;
}

.MuiExpansionPanel-root {
  box-shadow: none !important;
  border-bottom: 1px dashed #ebebed;

  &:last-child {
    border: none;
  }

  .MuiExpansionPanelSummary-root {
    padding: 0 16px;
  }

  .MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $dark-text;
    text-transform: uppercase;
  }
}

.MuiAccordionDetails-root,
.MuiAccordion-root.Mui-expanded,
.MuiExpansionPanel-root.Mui-expanded {
  margin: 0 !important;
  box-shadow: none;
}

.MuiAccordion-root.MuiPaper-elevation1 {
  box-shadow: none;
}

.MuiDialog-container .MuiDialog-paper {
  margin: 0;
}

.MuiExpansionPanel-root .MuiExpansionPanelDetails-root {
  padding: 0 16px;
}

.accordion-vertical {
  .MuiAccordionDetails-root {
    flex-direction: column;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    border-bottom: 1px dashed #ebebed;
  }
}

.MuiAccordionSummary-content {
  font-weight: 700;
}

.MuiPickersSlideTransition-transitionContainer .MuiTypography-root {
  font-size: 20px;
}

label.MuiFormLabel-root,
input.MuiInputBase-input {
  font-size: 16px;
  height: auto;
}

.MuiInputLabel-formControl .MuiInputLabel-asterisk {
  color: $error-text;
}

div.MuiPaper-elevation24,
.MuiDialog-paper.MuiPaper-elevation24 {
  box-shadow: none;
}

div.MuiInputBase-root {
  &:before,
  &:after {
    border-bottom: 1px solid $gray-border;
    transition: none;
  }
}

div.MuiInput-underline:hover:not(.Mui-disabled) {
  &:before,
  &:after {
    border-bottom: 2px solid $dark-border;
  }
}

div div.InputWithValueStyl {
  &:before,
  &:after {
    border-bottom: 2px solid $dark-border;
  }
}

div div.textFieldWithError {
  &:before,
  &:after {
    border-bottom: 1px solid $error-border;
    transition: none;

    &:focus {
      border-width: 2px;
    }
  }
}

.globalTab {
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    bottom: 0;
    left: 0;
    display: block;
    height: 4px;
    opacity: 0;
    background-color: $error-bg;
  }

  &.selected {
    position: relative;
    opacity: 1;

    &:after {
      opacity: 1;
    }
  }
}

.globalTabs {
  &.MuiTabs-root {
    min-height: 40px;
  }
  .MuiSvgIcon-root,
  .MuiIcon-root {
    font-size: 24px;
    color: $white-bg;
    opacity: 0.7;
  }
  .MuiTab-root {
    padding-top: 0;
    min-height: 40px;
  }
  .Mui-selected, .selected {
    .MuiIcon-root, .MuiSvgIcon-root {
      opacity: 1;
    }
  }
}

.globalFilterButton.MuiIconButton-root {
  padding: 1px;
  padding-left: 24px;

  .MuiIcon-root,
  .MuiSvgIcon-root {
    font-size: 24px;
    width: 24px;
    height: 24px;
  }
}

.flight-details-dialog-with-sidebar {
  .MuiBackdrop-root {
    bottom: 76px;
  }
}
