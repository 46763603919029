.preload-component {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  &__absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
